$(document).on("turbolinks:load", function () {
  const bindCreateNewLeague = function (e) {
    if (!window.activeLeagues || window.activeLeagues.length === 0) return;

    window.stopEvent(e);

    window.swalConfirm.fire({
      html: Mustache.render($("#mustache_create_league_warn").html()),
      showConfirmButton: false,
      showCancelButton: false,
      width: "600px",
    });
  };

  $("aside.left-sidebar .create-new-league-link").on("click", bindCreateNewLeague);

  $(document).on("click", ".create-link-go-to-league", function () {
    window.location.href = `/dashboards/leagues/${$(this).data("league-id")}`;
  });

  $(document).on("click", ".create-link-go-to-new-league", function () {
    $(".dataTables_wrapper .dataTable").DataTable().destroy(true);
    $.ajax({
      url: $("aside.left-sidebar .create-new-league-link").attr("href"),
      method: "POST",
      dataType: "script",
    });
  });

  $(document).on("click", ".renew-league-warning", function (e) {
    e.preventDefault();
    const courseId = $(this).attr("data-course-id");
    const leagueId = $(this).attr("data-league-id");
    const leagueName = $(this).attr("data-league-name");
    window.swalConfirm
      .fire({
        title: "Are you sure?",
        html: `This will renew ${leagueName} and will make it inactive.  Your newly renewed league will have your current settings and access to this leagues players. You cannot undo this process without contacting support.`,
        cancelButtonText: "No, cancel",
        confirmButtonText: "Yes, renew",
      })
      .then((result) => {
        if (!result.isConfirmed) return;

        $.ajax({
          type: "GET",
          url: `/courses/${courseId}/leagues/${leagueId}/renews/new?go_to_league=true`,
          dataType: "script",
        });
      });
  });
});
